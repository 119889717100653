export const environment = {
  production: false,
  projectBaseUrl: 'https://blueprint-q.dnaofsafety.net/api/projectsvc/',
  offerBaseURL: 'https://blueprint-q.dnaofsafety.net/api/offersvc',
  offerLibraryBaseURL: 'https://blueprint-q.dnaofsafety.net/api/offersvc/librarysvc',
  masterMachineSvcBaseUrl: 'https://blueprint-q.dnaofsafety.net/api/offersvc/mastermachinesvc',
  customerBaseUrl: 'https://blueprint-q.dnaofsafety.net/api/customersvc',
  orderBaseUrl: 'https://blueprint-q.dnaofsafety.net/api/ordersvc/',
  bucketSvcBaseUrl: 'https://blueprint-q.dnaofsafety.net/api/bucketsvc/',
  bucketMachineSvcBaseUrl: 'https://blueprint-q.dnaofsafety.net/api/bucketmachinesvc/',
  libraryBaseUrl: 'https://blueprint-q.dnaofsafety.net/api/librarysvc/',
  libraryMastermachineSvcBaseUrl: 'https://blueprint-q.dnaofsafety.net/api/librarysvc/mastermachinesvc/',
  libraryRoadmapSvcBaseUrl: 'https://blueprint-q.dnaofsafety.net/api/librarysvc/roadmapsvc/',
  notificationBaseUrl: 'https://blueprint-q.dnaofsafety.net/api/notificationsvc/',
  riskassessmentBaseUrl: 'https://blueprint-q.dnaofsafety.net/api/riskassessmentsvc/',
  userGroupBaseUrl: 'https://blueprint-q.dnaofsafety.net/api/group/',
  userBaseUrl: 'https://blueprint-q.dnaofsafety.net/api/',
  opportunitysvcBaseUrl: 'https://blueprint-q.dnaofsafety.net/api/offersvc/opportunitysvc',
  tokenEndpoint: 'https://blueprint-q.dnaofsafety.net',
  tokenScopes: ['api://b73ff917-9865-437e-aab8-43cc2f5c9317/.default'],
  msaAuthClientId: '96bd14ea-3d34-43c5-8a9d-6ec1f2282c04',

  riskAssessmentBaseUrl: 'https://schriskassessmentdev.azurewebsites.net/api/',
  // userMangBaseUrl: "https://schuserservice.azurewebsites.net",
  newOfferBaseUrl: 'https://offerapiservicedev.azurewebsites.net/',
  fourEyeQualityBaseUrl: 'https://blueprint-q.dnaofsafety.net/api/foureyesqualitysvc/',
  // updateOpportunityBaseURL: 'https://blueprint-q.dnaofsafety.net/api/offersvc/',
  profileBookmarkData: 'https://blueprint-q.dnaofsafety.net/api/user/',
  azureSearchKey:'d3KmxPeBdd0guITBdAI8GT9tAGrwE9C7migYU9sArGAzSeCRLDA1',


  commincationSiteName: 'Blueprint',
  commincationSiteFolderPath: '/Templates/Offer_Templates',
  workflows: 'https://blueprint-q.dnaofsafety.net/api/workflows/',
  userPrincipleName: 'blueprint.logicapp@dnaofsafetydev.onmicrosoft.com',
  azureSearch: 'https://dna-srch-westeurman-qa.search.windows.net/',
  launchFiory:'https://ft.kas-sap.net/?companyCode=',
  projectSvc : 'https://blueprint-q.dnaofsafety.net/api/projectsvc/service/'
};
